// partner logo

import AbsoluteResortsHotels from '../logo/brand-logos/absolute-resorts-hotels.png';
import AccorHotels from '../logo/brand-logos/accor-hotels.png';
import AvaniHotelsResorts from '../logo/brand-logos/avani-hotels-resorts.png';
import CentaraGrand from '../logo/brand-logos/centara-grand.png';
import DusitHotelResort from '../logo/brand-logos/dusit-hotel-resort.jpg';
import HolidayInn from '../logo/brand-logos/holiday-inn.png';
import Marriott from '../logo/brand-logos/marriott.png';
import RadissonHotels from '../logo/brand-logos/radisson-hotels.png';
import Radisson from '../logo/brand-logos/radisson.png';
import RembrandtHotelSuites from '../logo/brand-logos/rembrandt-hotel-suites.jpg';
import Whisgars from '../logo/brand-logos/whisgars.jpg';
import Punjab from '../logo/brand-logos/punjab.png';

//the work logo
import TheWorksLogo from '../logo/theworks-logo.png';

// section images

import Section1ImageA from '../images/section-1.webp';

import Section2ImageA from '../images/section-2a.webp';
import Section2ImageB from '../images/section-2b.webp';
import Section2ImageC from '../images/section-2c.webp';
import Section2ImageD from '../images/section-2d.webp';
import Section2ImageE from '../images/section-2e.webp';

import Section3ImageA from '../images/section-3a.webp';
import Section3ImageB from '../images/section-3b.webp';
import Section3ImageC from '../images/section-3c.webp';
import Section3ImageD from '../images/section-3d.webp';
import Section3ImageE from '../images/section-3e.webp';
import Section3ImageF from '../images/section-3f.webp';
import Section3ImageG from '../images/section-3g.webp';
import Section3ImageH from '../images/section-3h.webp';
import Section3ImageI from '../images/section-3i.webp';
import Section3ImageJ from '../images/section-3j.webp';
import Section3ImageK from '../images/section-3k.webp';
import Section3ImageL from '../images/section-3l.webp';

import Section4ImageA from '../images/section-4.webp';

// ------

export const partnerLogo = [
  AbsoluteResortsHotels,
  AccorHotels,
  AvaniHotelsResorts,
  CentaraGrand,
  DusitHotelResort,
  HolidayInn,
  Marriott,
  RadissonHotels,
  Radisson,
  RembrandtHotelSuites,
  Whisgars,
  Punjab,
];

export const theWorksLogo = [TheWorksLogo];

export const section1Images = [Section1ImageA];

export const section2Images = [
  Section2ImageA,
  Section2ImageB,
  Section2ImageC,
  Section2ImageD,
  Section2ImageE,
];
// export const section2Images = [];

export const section3Images = [
  Section3ImageA,
  Section3ImageB,
  Section3ImageC,
  Section3ImageD,
  Section3ImageE,
  Section3ImageF,
  Section3ImageG,
  Section3ImageH,
  Section3ImageI,
  Section3ImageJ,
  Section3ImageK,
  Section3ImageL,
];

export const section4Images = [Section4ImageA];
