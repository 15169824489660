import React, { Fragment } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';

// import Contact from './pages/Contact';
// import OurPartners from './pages/OurPartners';
import Landing from './pages/Landing';
import Error404 from './pages/Error404';
import Header from './UI/Header';
import Footer from './UI/Footer';
// import ComponentWrap from './UI/ComponentWrap';

// import logo from './logo.svg';
import './App.css';
import PreviousProjects from './pages/PreviousProjects';

function App() {
  const location = useLocation();
  return (
    <Fragment>
      {location.pathname !== '/' && <Header />}
      <Switch>
        <Route path="/" exact>
          <Landing />
        </Route>
        <Route path="/home" exact>
          <Home />
        </Route>
        <Route path="/about-us" exact>
          <AboutUs />
        </Route>
        <Route path="/previous-projects" exact>
          <PreviousProjects />
        </Route>
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
      {location.pathname !== '/' && <Footer />}
    </Fragment>
  );
}

export default App;
