import React, { Fragment } from 'react';

import classes from '../../assets/css/SectionsCss/MapSection.module.css';
import SectionHeader from '../SectionHeader';

const Section4 = function () {
  return (
    <Fragment>
      <div
        className={`container-fluid my-5 px-3 ${classes['map-section-grid']}`}
      >
        <div className="row">
          <div className="col-md-6">
            <SectionHeader>VIEW US ON THE MAP</SectionHeader>
            <SectionHeader className="mt-5">
              120/34 Moo 12 Soi Kingkaew 21/2 Kingkaew Rd., Rachathewa,
              Bangplee, Samutprakarn 10540
            </SectionHeader>
          </div>
          <div
            className={`col-md-6 text-center ${classes['map-address']}`}
            id="map-container"
          >
            <iframe
              title="address on google maps, medium size"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31012.96386719125!2d100.6956398!3d13.6808261!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d5dfb4363f091%3A0xc44c529558e17616!2sIMI%20Precision%20Engineering!5e0!3m2!1sen!2s!4v1695010394116!5m2!1sen!2s"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Section4;
