import React, { Fragment, useState, useEffect } from 'react';
import { section4Images } from '../../assets/js/imagesCollection';

import { getWindowDimension } from '../../Components/Navigation';

import classes from '../../assets/css/SectionsCss/Section4.module.css';
import SectionHeader from '../SectionHeader';

const Section4 = function () {
  const [windowDimension, setWindowDimension] = useState(getWindowDimension());

  useEffect(() => {
    function windowResize() {
      setWindowDimension(getWindowDimension());
    }
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener(
        'resize',
        setWindowDimension(getWindowDimension())
      );
    };
  }, []);

  return (
    <Fragment>
      <div className={`container my-5 ${classes['section-4-grid']}`}>
        <div className="row">
          <div className="col-sm-6 d-flex flex-column justify-content-center">
            <img
              className="img-fluid"
              src={section4Images}
              alt="pool"
              hidden={windowDimension.innerWidth < 576 && true}
            />
          </div>
          <div className="col-sm-6 d-flex flex-column align-self-center">
            <SectionHeader>MORE THAT WE DO</SectionHeader>
            <ul>
              <li>Interior design</li>
              <li>Project management</li>
              <li>Electrical teams</li>
              <li>Plumbers</li>
              <li>Ceilings</li>
              <li>Built-in wardrobes</li>
              <li>Lighting</li>
              <li>Kitchens (domestic and commercial)</li>
              <li>Drive ways and paths</li>
              <li>Painting</li>
              <li>Tiling</li>
              <li>Windows</li>
              <li>Saunas</li>
              <li>Furniture (in-door and out-door)</li>
              <li>Curtains</li>
              <li>Wallpaper</li>
              <li>Security cameras and alarms</li>
              <li>Roof insulation</li>
            </ul>
            <p className={classes['section-4-emphasis']}>
              And we can do every job from a Luxury condo to a VIP House or a
              full Hotel fit out.
            </p>
            <p className={classes['section-4-conclusion']}>
              Basically, Yes we can do THE WORKS
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Section4;
