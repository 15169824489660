import React, { Fragment, useEffect, useReducer, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useLocation } from 'react-router-dom';
import { Navigation, Thumbs } from 'swiper';

// import { partnerLogo } from '../assets/js/imagesCollection';
import { brandsData } from '../assets/js/projectsData';

import 'swiper/css';
import 'swiper/css/navigation';

import classes from '../assets/css/PreviousProjects.module.css';

const PreviousProjects = function () {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const location = useLocation();
  const getBrandImageDataHandler = function (location) {
    let filteredBrandsData = brandsData.filter((brand) => {
      return (
        `#${brand.brandName.replace(/\s/g, '').toLowerCase()}` === location.hash
      );
    });
    dispatchBrandImageData({ type: 'GET_DATA', data: filteredBrandsData });
  };

  useEffect(() => {
    getBrandImageDataHandler(location);
  }, [location]);

  const brandImageDataReducer = function (state, action) {
    if (action.type === 'GET_DATA') {
      return action.data;
    }
  };

  const [brandImageData, dispatchBrandImageData] = useReducer(
    brandImageDataReducer
  );

  return (
    <Fragment>
      <div className={`'container-fluid px-5 ${classes['brands-collection']}'`}>
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={6}
          loop={true}
          navigation={true}
          breakpoints={{
            1440: {
              slidesPerView: 6,
            },
            1024: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            320: {
              slidesPerView: 2,
            },
          }}
          className="px-5"
        >
          {brandsData &&
            brandsData.map((brand) => {
              return (
                <SwiperSlide
                  key={brand.brandName}
                  className="align-self-center my-3"
                >
                  <Link
                    to={{
                      hash: `#${brand.brandName
                        .replace(/\s/g, '')
                        .toLowerCase()}`,
                    }}
                  >
                    <img
                      src={brand.logo}
                      alt="partner-logo"
                      className={`${classes['partner-logo-item']}`}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
      <div className="container">
        <p>*click on the logo to see photo gallery</p>
      </div>

      <div
        className={`container-fluid my-5 ${classes['thumbnail-images-slider']}`}
      >
        <Swiper
          modules={[Navigation, Thumbs]}
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          slidesPerView={4}
          loop={true}
          className={`mt-3 p-3 ${classes['swiper-wrapper']}`}
        >
          {brandImageData
            ? brandImageData.map((item) => {
                return item.images.map((imgItem) => {
                  return (
                    <SwiperSlide
                      key={item.imgItem}
                      className="align-self-center"
                      style={{}}
                    >
                      <img
                        src={imgItem}
                        className={`${classes['swiper-images']}`}
                        alt={`'${item.brandName} item'`}
                      />
                    </SwiperSlide>
                  );
                });
              })
            : ''}
          {/* <SwiperSlide>image 1</SwiperSlide>
          <SwiperSlide>image 2</SwiperSlide>
          <SwiperSlide>image 3</SwiperSlide>
          <SwiperSlide>image 4</SwiperSlide> */}
        </Swiper>
        <Swiper
          modules={[Navigation, Thumbs]}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          navigation={true}
          className={`my-5 ${classes['swiper-image-large-wrapper']}`}
        >
          {brandImageData
            ? brandImageData.map((item) => {
                return item.images.map((imgItem) => {
                  return (
                    <SwiperSlide
                      key={item.imgItem}
                      className="align-self-center"
                      style={{}}
                    >
                      <img
                        src={imgItem}
                        className=""
                        alt={`'${item.brandName} item'`}
                      />
                    </SwiperSlide>
                  );
                });
              })
            : ''}
          {/* <SwiperSlide>image 1</SwiperSlide>
          <SwiperSlide>image 2</SwiperSlide>
          <SwiperSlide>image 3</SwiperSlide>
          <SwiperSlide>image 4</SwiperSlide> */}
        </Swiper>
      </div>
    </Fragment>
  );
};

export default PreviousProjects;
