import React, { Fragment } from 'react';

import '../assets/css/Home.css';

import Section1 from '../UI/sections/Section1';
import Section2 from '../UI/sections/Section2';
import Section3 from '../UI/sections/Section3';
import Section4 from '../UI/sections/Section4';
import MapSection from '../UI/sections/MapSection';

const Home = function () {
  return (
    <Fragment>
      <section id="section-1">
        <Section1 />
      </section>
      <section id="section-2">
        <Section2 />
      </section>
      <section id="section-3">
        <Section3 />
      </section>
      <section id="section-4">
        <Section4 />
      </section>
      <section id="map-section">
        <MapSection />
      </section>
    </Fragment>
  );
};

export default Home;

/*
 <div className="container p-5">
      <div className="p-5" id="about-us">
        <h2>
          “The Works” is a group of professionals led by a management team that
          is your one stop shop for EVERYTHING………
        </h2>
        <div className="container p-5">
          <div className="row">
            <div className="col">
              <ul>
                <li>Interior Design</li>
                <li>Project Management</li>
                <li>Electrical teams</li>
                <li>Plumbers</li>
                <li>Ceilings</li>
                <li>Built in Wardrobes</li>
                <li>Lighting</li>
                <li>Kitchens (domestic and commercial)</li>
                <li>Drive ways and Paths</li>
                <li>Painting</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li>Tiling</li>
                <li>Swimming Pools</li>
                <li>Windows</li>
                <li>Saunas and Steam room</li>
                <li>Furniture both indoor and outdoor</li>
                <li>Curtains</li>
                <li>Wallpaper</li>
                <li>Security Cameras and Alarms</li>
                <li>Roof Insulation</li>
              </ul>
            </div>
          </div>
        </div>
        <p>
          And we can do every job from a Luxury condo to a VIP House or a full
          Hotel fit out.
        </p>
        <br />

        <h3>Basically, Yes we can do THE WORKS</h3>
      </div>

      <div className="p-5" id="our-partners">
        <h2>Our partners</h2>
        <div className="container">
          <div className="row">
            <div className="col">
              <ul className="text-center">
                <li className="d-inline-block p-5">
                  <img
                    src={AbsoluteResortsHotels}
                    alt="absolute-resorts-hotels"
                  />
                </li>
                <li className="d-inline-block p-5">
                  <img src={AccordHotels} alt="accord-hotels" />
                </li>
                <li className="d-inline-block p-5">
                  <img src={AvaniHotelsResorts} alt="avani-hotels-resorts" />
                </li>
                <li className="d-inline-block p-5">
                  <img src={CentaraGrand} alt="centara-grand" />
                </li>
                <li className="d-inline-block p-5">
                  <img src={DusitHotelResort} alt="dusit-hotel-resort" />
                </li>
                <li className="d-inline-block p-5">
                  <img src={HolidayInn} alt="holiday-inn" />
                </li>
                <li className="d-inline-block p-5">
                  <img src={Marriott} alt="marriott" />
                </li>
                <li className="d-inline-block p-5">
                  <img src={RadissonHotels} alt="radisson-hotels" />
                </li>
                <li className="d-inline-block p-5">
                  <img src={Radisson} alt="radisson" />
                </li>
                <li className="d-inline-block p-5">
                  <img
                    src={RembrandtHotelSuites}
                    alt="rembrandt-hotel-suites"
                  />
                </li>
                <li className="d-inline-block p-5">
                  <img src={Whisgars} alt="whisgars" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
*/
