import React, { Fragment, useEffect, useState } from 'react';

import { getWindowDimension } from '../Components/Navigation';
import { theWorksLogo } from '../assets/js/imagesCollection';

import classes from '../assets/css/AboutUs.module.css';

const AboutUs = function () {
  const [windowDimension, setWindowDimension] = useState(getWindowDimension());

  useEffect(() => {
    function windowResize() {
      setWindowDimension(getWindowDimension());
    }
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener(
        'resize',
        setWindowDimension(getWindowDimension())
      );
    };
  }, []);

  return (
    <Fragment>
      <div className="container header-container mt-5">
        <h1 className={classes['header-title']}>ABOUT THE WORKS</h1>
      </div>
      <div
        className={`${
          windowDimension.innerWidth < 992 ? 'container-fluid' : 'container'
        } ${windowDimension.innerWidth < 992 ? 'mt-5' : 'my-5'}  p-5 ${
          classes['about-us-container']
        }`}
      >
        <div className=" my-5 d-flex justify-content-start">
          <img
            className={`${classes['logo']}`}
            src={theWorksLogo}
            alt="logo"
            id={`${classes['the-works-logo']}`}
          />
        </div>
        <div className={`container text-center my-5 ${classes['intro-line']}`}>
          <p>"The Works team are here at your service!"</p>
        </div>

        <div className="row">
          <div className="col-md-6">
            <p>
              The Works has now been a major manufacturing and export company
              for nearly 40 years, dealing with everything from a single bespoke
              chair to an entire fit-out for international Chain Hotels. Our
              dedicated professional team have worked with people from all over
              the world to make all projects come true. We also have a lot of
              experience supplying retail stores from over 60 countries.
            </p>
            <p>
              Our team also loves working together with interior designers,
              architects and buyers. We have been working with many companies
              around the globe, and our import and export manager allows for
              smooth sourcing and on-time deliveries.
            </p>
          </div>
          <div className="col-md-6">
            <p>
              We only source and use the best sustainable raw materials to build
              high-end quality furniture, with a frame guarantee of 25 years on
              our sofas. We know we have the confidence to help condo developers
              and Hoteliers get a durable product that will last. Again, for
              personal customers, we can recommend from over 600 existing
              in-house designs that we can amend or manufacture a bespoke
              service from Design to Delivery just for you.
            </p>
          </div>
          <div className="col-md-6">
            <p className={`mt-5 ${classes['member-title']}`}>
              Matthew Slater - CEO
            </p>
            <p>
              Matthew Slater from England is our CEO and has worked in many
              different professions. He learnt early in his career that service
              and quality are paramount to long-term happy customers that
              confidentially return months and months, year after year, and
              sometimes they bring a friend.
            </p>
          </div>
          <div className="col-md-6">
            <p className={`mt-5 ${classes['member-title']}`}>
              Dilok Kaewbao (Att) - Production manager
            </p>
            <p>
              Dilok Kaewbao (Att) from Thailand is our Production manager and
              again was brought up with a service goal orientation. With over 30
              years of training and experience, he is happy to work with
              customers to ensure nothing is overlooked and that no detail is
              too small.
            </p>
          </div>
          <div className="col-md-6">
            <p className={`mt-5 ${classes['member-title']}`}>
              Eddy Bellavoine - Director of Marketing
            </p>
            <p>
              Eddy Bellavoine from France is our Director of Marketing. No job
              is too big or too small that he takes them all. Anyone who meets
              Eddy instantly knows that they are in the best and most
              professional hands.
            </p>
          </div>
          <div className="col-md-6">
            <p className={`mt-5 ${classes['member-title']}`}>
              Charlie - Project Manager
            </p>
            <p>
              Charlie also from the UK has managed and overseen the building of
              Large condo projects and luxury Private VIP homes for many years.
              he is here to make sure no detail goes unmissed
            </p>
          </div>
        </div>
        <div className={`container mt-5 ${classes['conclusion']}`}>
          <p>
            We have the greatest pleasure of taking care of orders from around
            the world, from Royal Families to our own families and from a Studio
            Condo to a Towering Hotel complex.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutUs;
