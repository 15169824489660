import React, { Fragment, useState, useEffect } from 'react';

import '../assets/css/Footer.css';

import { theWorksLogo } from '../assets/js/imagesCollection';

// const mapsMedium = (
//   <iframe
//     title="address on google maps, medium size"
//     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.620468576182!2d100.7162394!3d13.680827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d67563f9ca64b%3A0x478aa6c88c955b43!2sAsalon%20Co.%20Ltd!5e0!3m2!1sen!2s!4v1671539896333!5m2!1sen!2s"
//     width="600"
//     height="450"
//     style={{ border: 0 }}
//     allowFullScreen=""
//     loading="lazy"
//     referrerPolicy="no-referrer-when-downgrade"
//   ></iframe>
// );
// const mapsSmall = (
//   <iframe
//     title="address on google maps, small size"
//     src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7753.240937152364!2d100.716239!3d13.680827!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x478aa6c88c955b43!2sAsalon%20Co.%20Ltd!5e0!3m2!1sen!2s!4v1671624676708!5m2!1sen!2s"
//     width="300"
//     height="300"
//     style={{ border: 0 }}
//     allowFullScreen=""
//     loading="lazy"
//     referrerPolicy="no-referrer-when-downgrade"
//   ></iframe>
// );

const getWindowDimension = function () {
  const { innerHeight, innerWidth, pageYOffset } = window;
  return { innerHeight, innerWidth, pageYOffset };
};

const Footer = function () {
  const [windowDimension, setWindowDimension] = useState(getWindowDimension());

  useEffect(() => {
    function windowResize() {
      setWindowDimension(getWindowDimension());
    }
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener(
        'resize',
        setWindowDimension(getWindowDimension())
      );
    };
  }, []);
  return (
    <Fragment>
      <footer
        className={`container-fluid d-flex px-5 mt-auto ${
          windowDimension.innerWidth < 768 && 'flex-wrap flex-column'
        }`}
        id="footer-container"
      >
        <div id="contact"></div>
        <div className="flex-fill p-5 d-flex">
          <img
            src={theWorksLogo}
            className="img-fluid m-auto"
            alt="the works logo"
            id="the-works-logo"
          />
        </div>
        <div className="flex-fill p-5">
          <h2 className="py-3">contact</h2>
          <p>Matthew Slater</p>
          <ul>
            <li>
              <i className="fa-solid fa-phone d-inline"></i>&nbsp;&nbsp;&nbsp;
              <a href="tel:+66 86 688 1035" className="d-inline">
                +66 86 688 1035
              </a>
            </li>
            <li>
              <i className="fa-solid fa-envelope d-inline"></i>
              &nbsp;&nbsp;&nbsp;
              <a href="mailto:matt@asalon.co.th" className="d-inline">
                matt@asalon.co.th
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-fill p-5">
          <h2 className="py-3">address</h2>
          <p>ASALON CO., LTD.</p>
          <ul>
            <li>
              <p>
                120/34 Moo 12 Soi Kingkaew 21/2 Kingkaew Rd., Rachathewa,
                Bangplee, Samutprakarn 10540
              </p>
            </li>

            <li></li>
          </ul>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
