import React, { Fragment } from 'react';
import Navigation from '../Components/Navigation';

const Header = function () {
  return (
    <Fragment>
      <Navigation />
    </Fragment>
  );
};

export default Header;
