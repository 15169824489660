import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import '../assets/css/Landing.css';

import { theWorksLogo } from '../assets/js/imagesCollection';

const Landing = function () {
  const getWindowDimension = function () {
    const { innerHeight } = window;
    return { innerHeight };
  };
  const [windowDimension, setWindowDimension] = useState(getWindowDimension());
  useEffect(() => {
    function windowResize() {
      setWindowDimension(getWindowDimension());
    }
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener(
        'resize',
        setWindowDimension(getWindowDimension())
      );
    };
  }, []);

  return (
    <Link to="/home" id="landing-link">
      <div
        className="container-fluid d-flex flex-column"
        id="landing-container"
        style={{ height: `${windowDimension.innerHeight}px` }}
      >
        <img
          src={theWorksLogo}
          className="img-fluid m-auto"
          alt="the works logo"
        />
        <p className="align-self-end mt-auto me-3" id="tag-line">
          The Works guaranteed
        </p>
      </div>
    </Link>
  );
};

export default Landing;
