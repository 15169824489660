import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { theWorksLogo } from '../assets/js/imagesCollection';

import '../assets/css/Navigation.css';

export const getWindowDimension = function () {
  const { innerHeight, innerWidth, pageYOffset } = window;
  return { innerHeight, innerWidth, pageYOffset };
};

const NavigationItem = function (props) {
  return (
    <li className={'nav-item px-3'}>
      <NavLink
        className={'nav-link px-2'}
        activeClassName={'customActive'}
        to={props.path}
        id={props.id}
        exact={true}
        isActive={props.isActive}
      >
        {props.title}
      </NavLink>
    </li>
  );
};

const Navigation = function () {
  const [windowDimension, setWindowDimension] = useState(getWindowDimension());
  // const [isScrollFar, setIsScrollFar] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const contactNavItem = document.getElementById('contact-nav-item');
    const contactSection = document.getElementById('contact');
    const goToSection = function () {
      history.push('#contact');

      if (history.location.hash) {
        contactSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };
    contactNavItem.addEventListener('click', goToSection);
  }, [history]);

  useEffect(() => {
    function windowResize() {
      setWindowDimension(getWindowDimension());
    }
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener(
        'resize',
        setWindowDimension(getWindowDimension())
      );
    };
  }, []);

  // useEffect(() => {
  //   function windowScroll() {
  //     const { innerHeight, pageYOffset } = getWindowDimension();
  //     let scrollCalculatePercentage = (0.03 * innerHeight) / 0.1;
  //     if (pageYOffset > scrollCalculatePercentage) {
  //       setIsScrollFar(true);
  //     }
  //     if (pageYOffset < scrollCalculatePercentage) {
  //       setIsScrollFar(false);
  //     }
  //   }
  //   window.addEventListener('scroll', windowScroll);

  //   return () => {
  //     window.removeEventListener('scroll', windowScroll);
  //   };
  // }, []);

  return (
    <Fragment>
      {/* <div id="top"></div> */}
      <nav className={'navbar navbar-expand-lg'}>
        <div
          className={`container p-5 ${
            windowDimension.innerWidth <= 768 && 'flex-column'
          }`}
          id="nav-container"
        >
          <NavLink className={'navbar-brand'} to="home">
            <img src={theWorksLogo} alt="the works logo" id="the-works-logo" />
          </NavLink>
          <button
            className={'navbar-toggler py-3'}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>
              <i className={'fa-solid fa-bars'}></i>
            </span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              windowDimension.innerWidth >= 768 && 'justify-content-end'
            }`}
            id="navbarNav"
          >
            <ul className={'navbar-nav'}>
              <NavigationItem title="home" path="/home" />
              <NavigationItem title="about us" path="/about-us" />
              <NavigationItem
                title="previous projects"
                path="/previous-projects"
              />
              <NavigationItem
                title="contact"
                path="#contact"
                id="contact-nav-item"
                isActive={(match, location) => {
                  if (!match) {
                    return false;
                  }
                }}
              />

              {/* <NavigationItem title="our partners" path="/our-partners" /> */}
            </ul>
          </div>
        </div>
        {/* <a
          href="#top"
          id="back-to-top"
          style={{ display: isScrollFar ? 'block' : 'none' }}
        >
          <i className={'fa-solid fa-arrow-up'}></i>
          <p>back to top</p>
        </a> */}
      </nav>
    </Fragment>
  );
};

export default Navigation;
