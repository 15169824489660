import AbsoluteResortsHotels from '../logo/brand-logos/absolute-resorts-hotels.png';
import AccorHotels from '../logo/brand-logos/accor-hotels.png';
import AvaniHotelsResorts from '../logo/brand-logos/avani-hotels-resorts.png';
import CentaraGrand from '../logo/brand-logos/centara-grand.png';
import DusitHotelResort from '../logo/brand-logos/dusit-hotel-resort.jpg';
import HolidayInn from '../logo/brand-logos/holiday-inn.png';
import Marriott from '../logo/brand-logos/marriott.png';
import RadissonHotels from '../logo/brand-logos/radisson-hotels.png';
import Radisson from '../logo/brand-logos/radisson.png';
import RembrandtHotelSuites from '../logo/brand-logos/rembrandt-hotel-suites.jpg';
import Whisgars from '../logo/brand-logos/whisgars.jpg';
import Punjab from '../logo/brand-logos/punjab.png';
import Amari from '../logo/brand-logos/amari-watergate-bangkok.png';

import { whisgarsImages } from './WhisgarData';
import { punjabImages } from './PunjabData';
import { avaniImages } from './AvaniData';
import { amariImages } from './AmariData';
import { absoluteImages } from './AbsoluteData';
import { radissonImages } from './RadissonData';

export const brandsData = [
  {
    brandName: 'Absolute Resorts & Hotels',
    logo: AbsoluteResortsHotels,
    images: [...absoluteImages],
  },
  {
    brandName: 'Accor Hotels',
    logo: AccorHotels,
    images: [],
  },
  {
    brandName: 'Avani Hotels & Resorts',
    logo: AvaniHotelsResorts,
    images: [...avaniImages],
  },
  {
    brandName: 'Dusit Thani Hotels & Resorts',
    logo: DusitHotelResort,
    images: [],
  },
  {
    brandName: 'Centara Grand',
    logo: CentaraGrand,
    images: [],
  },
  {
    brandName: 'Holiday Inn',
    logo: HolidayInn,
    images: [],
  },
  {
    brandName: 'Marriott Hotels-Resorts-Suites',
    logo: Marriott,
    images: [],
  },
  {
    brandName: 'Radisson Hotels',
    logo: RadissonHotels,
    images: [],
  },
  {
    brandName: 'Radisson',
    logo: Radisson,
    images: [...radissonImages],
  },
  {
    brandName: 'Rembrandt Hotels & Suites',
    logo: RembrandtHotelSuites,
    images: [],
  },
  {
    brandName: 'Whisgars',
    logo: Whisgars,
    images: [...whisgarsImages],
  },
  {
    brandName: 'Punjab',
    logo: Punjab,
    images: [...punjabImages],
  },
  {
    brandName: 'Amari Watergate Bangkok',
    logo: Amari,
    images: [...amariImages],
  },
];
