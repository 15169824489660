import React, { Fragment } from 'react';

import { section1Images } from '../../assets/js/imagesCollection';

import classes from '../../assets/css/SectionsCss/Section1.module.css';

const Section1 = function () {
  return (
    <Fragment>
      <div className={`container-fluid mb-5 ${classes['section-1-container']}`}>
        <img className="img-fluid" src={section1Images} alt="living room" />
        <p className={classes['text-on-image']}>
          “The Works” is a group of professionals led by a management team that
          is your one stop shop for EVERYTHING………
        </p>
      </div>
    </Fragment>
  );
};

export default Section1;
