import React, { Fragment } from 'react';

import SectionHeader from '../SectionHeader';

import classes from '../../assets/css/SectionsCss/Section3.module.css';

import { section3Images } from '../../assets/js/imagesCollection';

const Section3 = function () {
  return (
    <Fragment>
      <div className={`container my-5 ${classes['section-3-header']}`}>
        <SectionHeader>Bars and Super Yatch</SectionHeader>
      </div>
      <div className={`container my-5 ${classes['section-3-grid']}`}>
        <div className="row">
          {section3Images.map((image) => {
            return (
              <div className="col-xs-1 col-sm-6 col-xl-4 mb-4" key={image}>
                <img
                  className="img-fluid mx-auto"
                  src={image}
                  alt="list items"
                />
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default Section3;
