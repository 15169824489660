import img1 from '../images/radisson/20191202195626.webp';
import img2 from '../images/radisson/20191205_150541.webp';
import img3 from '../images/radisson/20191205_152440.webp';
import img4 from '../images/radisson/20191205_154042.webp';
import img5 from '../images/radisson/20191205_185159.webp';
import img6 from '../images/radisson/DSCF7453-Edit.webp';
import img7 from '../images/radisson/DSCF7470-Edit.webp';
import img8 from '../images/radisson/DSCF7492-Edit.webp';
import img9 from '../images/radisson/DSCF7519-Edit.webp';
import img10 from '../images/radisson/DSCF7529-Edit.webp';

export const radissonImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
];
