import React, { Fragment, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';

import SectionHeader from '../SectionHeader';
import { section2Images } from '../../assets/js/imagesCollection';

import classes from '../../assets/css/SectionsCss/Section2.module.css';

const Section2 = function () {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Fragment>
      <div
        className={`container d-flex flex-column my-5 ${classes['section-2-container']}`}
      >
        <SectionHeader>Houses and Condos</SectionHeader>
        {/* carousel */}
        {/* <div
          id="carousel-container"
          className="carousel slide my-3"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {section2Images.map((item, idx) => {
              return (
                <div
                  className={`carousel-item ${idx === 0 && 'active'}`}
                  key={item}
                >
                  <img
                    src={item}
                    className="d-block w-100"
                    alt="carousel-item"
                  />
                </div>
              );
            })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carousel-container"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carousel-container"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div> */}

        <Carousel activeIndex={index} onSelect={handleSelect}>
          {section2Images.map((item, idx) => {
            return (
              <Carousel.Item key={item + idx}>
                <img className="d-block w-100" src={item} alt="First slide" />
              </Carousel.Item>
            );
          })}
        </Carousel>

        {/* carousel */}
      </div>
    </Fragment>
  );
};

export default Section2;
