import React, { Fragment } from 'react';

import '../assets/css/Error404.css';

const Error404 = function () {
  return (
    <Fragment>
      <p className="error-message">Page Not Found</p>
    </Fragment>
  );
};

export default Error404;
