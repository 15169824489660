import img1 from '../images/absolute/Master_Bedroom.webp';
import img2 from '../images/absolute/P8080171-871-1-1024x768.webp';
import img3 from '../images/absolute/P8080193-879-1024x686.webp';
import img4 from '../images/absolute/P8080200-886-1-1024x728.webp';
import img5 from '../images/absolute/P8080217-921-1024x743.webp';
import img6 from '../images/absolute/P8080232-941-950-1024x792.webp';
import img7 from '../images/absolute/P8080241-951-1024x730.webp';
import img8 from '../images/absolute/P8080252-957-1024x768.webp';
import img9 from '../images/absolute/Typical-Bathroom.webp';

export const absoluteImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
];
