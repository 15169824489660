import React from 'react';
import classes from '../assets/css/SectionsCss/SectionHeader.module.css';

const SectionHeader = function (props) {
  return (
    <h1 className={`${classes['section-header']} ${props.className}`}>
      {props.children}
    </h1>
  );
};
export default SectionHeader;
